import React from "react";
import { Typography, Button } from "antd";
import { Link } from "gatsby";
import number from './../images/Error500Page.png'

const {Title , Paragraph} = Typography

const unexpectedError = () => {
  return(
    <main className="error-page-wrapper">
      <Title level={3} className="title-error-page">Error: 500 Unexpected Error</Title>
      <div className="number-wrapper">
        <img src={number}/>
      </div>
      <div className="button-and-text"> 
          <Paragraph className="error-page-text">Przepraszam, coś poszło nie tak.</Paragraph>
        <div className="button-wrapper">
          <Button className='button-error-page'><Link to='/' className='link-error-page'>Cofnij się</Link></Button>
        </div>
      </div>
    </main>
  )
}

export default unexpectedError;